<template>
  <div id="program-data">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="blockquote-left" /> Programa</h1>
        </div>
        <div class="card-body">
          <div class="row px-2">
            <div class="container col-lg-7 col-md-12">
              <div class="card" style="height: 100%">
                <div class="card-header">
                  <h2>{{ id }}</h2>
                </div>
                <div class="card-body">
                  <div class="container">
                    <div>
                      <b-row>
                        <b-col class="objetfit text-center">
                          <b-img
                            thumbnail
                            fluid
                            src="https://www.unsa.edu.pe/ucps/wp-content/uploads/2021/11/1500x844_habilidades_responsables_seguridad_salud_trabajo.jpg"
                            alt="Imagen de perfil"
                          ></b-img>
                        </b-col>
                      </b-row>
                      <b-row class="p-3">
                        <p>
                          Cras sit amet nibh libero, in gravida nulla. Nulla vel
                          metus scelerisque ante sollicitudin. Cras purus odio,
                          vestibulum in vulputate at, tempus viverra turpis.
                          Fusce condimentum nunc ac nisi vulputate fringilla.
                          Donec lacinia congue felis in faucibus. Cras sit amet
                          nibh libero, in gravida nulla. Nulla vel metus
                          scelerisque ante sollicitudin. Cras purus odio,
                          vestibulum in vulputate at, tempus viverra turpis.
                          Fusce condimentum nunc ac nisi vulputate fringilla.
                          Donec lacinia congue felis in faucibus.
                        </p>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container col-lg-5 col-md-12">
              <div class="card" style="height: 100%">
                <div class="card-header">
                  <h2>Información</h2>
                </div>
                <div class="card-body">
                  <div>
                    <b-list-group>
                      <b-list-group-item class="flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">List group item heading</h5>
                          <small>3 days ago</small>
                        </div>

                        <p class="mb-1">
                          Donec id elit non mi porta gravida at eget metus.
                          Maecenas sed diam eget risus varius blandit.
                        </p>

                        <small>Donec id elit non mi porta.</small>
                      </b-list-group-item>

                      <b-list-group-item class="flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">List group item heading</h5>
                          <small class="text-muted">3 days ago</small>
                        </div>

                        <p class="mb-1">
                          Donec id elit non mi porta gravida at eget metus.
                          Maecenas sed diam eget risus varius blandit.
                        </p>

                        <small class="text-muted"
                          >Donec id elit non mi porta.</small
                        >
                      </b-list-group-item>

                      <b-list-group-item class="flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Disabled List group item</h5>
                          <small class="text-muted">3 days ago</small>
                        </div>

                        <p class="mb-1">
                          Donec id elit non mi porta gravida at eget metus.
                          Maecenas sed diam eget risus varius blandit.
                        </p>

                        <small class="text-muted"
                          >Donec id elit non mi porta.</small
                        >
                      </b-list-group-item>
                    </b-list-group>
                    <b-button
                      type="button"
                      @click="programEnter"
                      class="mt-3 w-100"
                      style="background-color: #1d425e; border: none"
                      >Matricularse</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: "Vue!",
    },
  },
  data() {
    return {};
  },
  methods: {
    programEnter() {
      this.$router.push("/request/"+this.id);
    },
  },
};
</script>

<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.objetfit > img {
  width: 100%;
  height: 300px;
}
</style>